<template>
  <div class="asset-depreciation-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="DetailInfo" :tab="$t(`折旧汇总`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.orderStatus === 'WAIT_CHECK'"
            api="operateAssetDepreciation"
            :text="$t('审核')"
            :params="{
              action: 'CHECK',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_PAY'].includes(formData.orderStatus)"
            api="operateAssetDepreciation"
            type="danger"
            :text="$t('作废')"
            :params="{
              action: 'DELETE',
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="isDetail && !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.orderStatus)"
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox id="BaseInfo" :title="$t('基本信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据编号`)">
                    <a-input disabled v-model="formData.depreciationNo"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`货主组织`)" required>
                    <a-input disabled :value="formData.ownerBusinessUnitName" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`会计政策`)" required>
                    <a-input disabled :value="formData.accountingPolicyName" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`折旧期间`)" required>
                    <a-input disabled :value="formData.accountingPeriodName" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`状态`)" required>
                    <a-input disabled :value="assetOperateStatusMapping[formData.orderStatus]" />
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="DetailInfo" :title="$t('折旧汇总')">
              <CommonTable
                :columns="columns"
                :dataSource="formData.detailList"
                :scroll="{ x: 1500 }"
                :showSelect="!isDisabled"
                :showPagination="false"
                :showSetting="false"
              >
                <template v-slot:assetCardNo="{ record }">
                  <span
                    class="text-link"
                    @click="jumpSoucePage(record.assetCardNo, record.assetCardId)"
                  >
                    {{ record.assetCardNo }}
                  </span>
                </template>
                <template v-slot:depreciationAmount="{ text }">
                  <PriceInput :value="text" :showTxt="true" />
                </template>
                <template v-slot:accruedDepreciationAmount="{ text }">
                  <PriceInput :value="text" :showTxt="true" />
                </template>
                <template v-slot:manualAdjustInfluenceFuture="{ text }">
                  <a-switch disabled :chedked="Boolean(+text)"></a-switch>
                </template>
              </CommonTable>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.orderStatus"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import BottomBtns from '@component/bottomBtns'
import OperationLog from '@component/operationLog'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack, jumpSoucePage } from '@/common'
import { add } from '@/common/number'
export default {
  name: 'AssetDepreciationDetail',
  components: { BottomBtns, OperationLog },
  data() {
    return {
      docType: 'AssetDepreciation',
      id: undefined,
      sn: undefined,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        orderStatus: 'DRAFT',
        detailList: [],
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapGetters(['assetOperateStatusMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 280 + 'px'
    },
    columns() {
      const list = [
        {
          title: this.$t('资产编码'),
          dataIndex: 'assetCode',
        },
        {
          title: this.$t('卡片编号'),
          dataIndex: 'assetCardNo',
          scopedSlots: {
            customRender: 'assetCardNo',
          },
        },
        {
          title: this.$t('资产名称'),
          dataIndex: 'assetName',
        },
        {
          title: this.$t('本期折旧额'),
          dataIndex: 'depreciationAmount',
          scopedSlots: {
            customRender: 'depreciationAmount',
          },
        },
        {
          title: this.$t('应提折旧额'),
          dataIndex: 'accruedDepreciationAmount',
          scopedSlots: {
            customRender: 'accruedDepreciationAmount',
          },
        },
        {
          title: this.$t('手工调整折旧额影响后续期间'),
          dataIndex: 'manualAdjustInfluenceFuture',
          scopedSlots: {
            customRender: 'manualAdjustInfluenceFuture',
          },
        },
      ]
      return list
    },
  },
  methods: {
    jumpSoucePage,
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    chooseAccountingDate(data) {
      this.formData.accountingDate = data.data
    },
    async getDetailInfo() {
      let payableOrderFormdata = localStorage.getItem('payableOrderFormdata')
      if (this.id) {
        this.loading = true
        await http({
          url: api.getAssetDepreciationDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(res.result)
            let totalAmount1 = 0,
              totalAmount2 = 0
            result.detailList.forEach((item) => {
              totalAmount1 = add(totalAmount1, item.depreciationAmount || 0)
              totalAmount2 = add(totalAmount2, item.accruedDepreciationAmount || 0)
            })
            result.detailList.push({
              type: 'summary',
              depreciationAmount: totalAmount1,
              accruedDepreciationAmount: totalAmount2,
            })
            this.formData = deepClone(result)
            if (result.orderStatus === 'DRAFT') {
              this.isEdit = true
            }
          },
        })
        this.loading = false
      } else if (payableOrderFormdata) {
        //复制
        let data = JSON.parse(payableOrderFormdata)
        this.formData = data
        this.formData.paymentNoteSn = undefined
        this.formData.id = undefined
        this.formData.orderStatus = 'DRAFT'
        this.tableData = deepClone(this.formData.tableData)
        delete this.formData.tableData
        localStorage.removeItem('payableOrderFormdata')
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    copyOrder() {
      this.formData.tableData = this.tableData
      this.formData.paymentPlanList = this.paymentPlanList
      let data = JSON.stringify(this.formData)
      localStorage.setItem('payableOrderFormdata', data)
      this.$router.push({ name: 'payableDetail' })
    },
    backForm(back = 0) {
      goBack('assetDepreciationList', back)
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      data.orderStatus = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      await http({
        url: api.createPaymentRequest,
        data: {
          paymentRequestReqVO: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
  },
}
</script>
